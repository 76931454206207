import axios from 'axios';

export const getEventsByDate = async (offset, from, to, city) => {
    const fromStr = new Date(from).toISOString().slice(0, 10);
    const toStr = new Date(to).toISOString().slice(0, 10);
    const request = 'https://5ay52hgda3.execute-api.eu-central-1.amazonaws.com/PROD/events?'
        + 'offset=' + offset
        + '&from=' + fromStr
        + '&to=' + toStr
        + '&city=' + city
        
    return await axios.get(request).then(res => res.data);
};