import ReactGA from 'react-ga';
import { dateStr } from './dates';

export const initGA = () => ReactGA.initialize('UA-137908333-1');

export const gaPageView = () => ReactGA.pageview(window.location.pathname + window.location.search);

export const gaShowMoreBtnClick = (fromStr, toStr) => {
    const from = dateStr(fromStr);
    const to = dateStr(toStr);

    ReactGA.event({
        category: 'Event Serach by Date',
        action: 'Show More Button',
        label: 'show-more-events'
            + '|from:' + from
            + '|to:' + to
    });
};