import React, { Component } from 'react';
import EventList from '../EventList';
import DateSearch from '../DateSearch';
import ShowMoreBtn from '../ShowMoreBtn';
import { getEventsByDate } from '../../functions/search-events';
import { gaShowMoreBtnClick, gaPageView } from '../../functions/ga';


class CustomDates extends Component {
    state = {
        events: [],
        offset: 0,
        hasMoreItems: false,
        total: 0,
        fromDateStr: '',
        toDateStr: ''
    }

    // searching events with the date range
    dateSearch = (offset, from, to) => {
        getEventsByDate(offset, from, to, this.props.city).then(res => {
            this.setState({
                events: offset === 0 ? [...res.events] : [...this.state.events, ...res.events],
                offset: res.nextOffset,
                hasMoreItems: res.hasMoreItems,
                total: res.total,
                fromDateStr: from,
                toDateStr: to
            })
        })
    };

    //loading more event
    showMoreEvents = () => {
        const from = this.state.fromDateStr;
        const to = this.state.toDateStr;
        gaShowMoreBtnClick(from, to);
        this.dateSearch(this.state.offset, from, to);
    };

    componentDidMount(){
        gaPageView();
    }


    render() {
        return (
            <React.Fragment>
                <DateSearch dateSearch={this.dateSearch} />
                <EventList events={this.state.events} />
                {this.state.hasMoreItems && <ShowMoreBtn showMore={this.showMoreEvents} />}
            </React.Fragment>
        )
    }
}

export default CustomDates;