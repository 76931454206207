import React, { Component } from 'react';
import EventList from '../EventList';
import ShowMoreBtn from '../ShowMoreBtn';
import { getEventsByDate } from '../../functions/search-events';
import { gaPageView, gaShowMoreBtnClick } from '../../functions/ga';
import { todayDateStr } from '../../functions/dates';


class Today extends Component {
    state = {
        events: [],
        offset: 0,
        hasMoreItems: false,
        total: 0
    }

    // searching events with the date range
    dateSearch = (offset) => {
        getEventsByDate(offset, todayDateStr(), todayDateStr(), this.props.city).then(res => {
            this.setState({
                events: offset === 0 ? [...res.events] : [...this.state.events, ...res.events],
                offset: res.nextOffset,
                hasMoreItems: res.hasMoreItems,
                total: res.total
            })
        })
    };

    //loading more event
    showMoreEvents = () => {
        gaShowMoreBtnClick(todayDateStr(), todayDateStr());

        this.dateSearch(this.state.offset);
    }

    componentDidMount() {
        gaPageView();
        this.dateSearch(this.state.offset);
    };

    render() {
        return (
            <React.Fragment>
                <EventList events={this.state.events} />
                {this.state.hasMoreItems && <ShowMoreBtn showMore={this.showMoreEvents} />}
            </React.Fragment>
        )
    }
}

export default Today;