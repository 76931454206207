import React, { Component } from 'react';
import ReactGA from 'react-ga';

export class DateSearch extends Component {
    state = {
        from: '',
        to: ''
    };

    onSubmit = (e) => {
        e.preventDefault();
        ReactGA.event({
            category: 'Event Serach by Date',
            action: 'Search Button',
            label: 'event-search'
                + '|from:' + new Date(this.state.from).toISOString().substr(0, 10)
                + '|to:' + new Date(this.state.to).toISOString().substr(0, 10)
        });
        this.props.dateSearch(0, this.state.from, this.state.to);
    };

    onChangeFrom = (event) => {
        const newValue = event.target.value
        ReactGA.event({
            category: 'Event Serach by Date',
            action: 'Select From Date',
            label: new Date(newValue).toISOString().substr(0, 10)
        });
        this.setState({ from: newValue, to: newValue })
    };

    onChangeTo = (event) => {
        const toDateStr = event.target.value
        ReactGA.event({
            category: 'Event Serach by Date',
            action: 'Select To Date',
            label: new Date(toDateStr).toISOString().substr(0, 10)
        });
        this.setState({ to: toDateStr })
    };

    componentDidMount() {
        const today = new Date().toISOString().substr(0, 10);
        this.setState({ from: today, to: today });
    };

    render() {
        return (
            <form className="search-form" onSubmit={this.onSubmit} >
                <input
                    className="date-input"
                    type="date"
                    name="from"
                    placeholder="From"
                    value={this.state.from}
                    onChange={this.onChangeFrom} />
                <input
                    className="date-input"
                    type="date"
                    name="to"
                    placeholder="To"
                    value={this.state.to}
                    onChange={this.onChangeTo} />
                <input
                    type="submit"
                    value="Search"
                    className="btn" />
            </form>
        )
    }
}

export default DateSearch;