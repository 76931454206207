import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class QuickFilter extends Component {
    filterClicked = (e) => {
        e.preventDefault();
        console.log(e.target.id);
    }

    render() {
        return (
            <div className="quick-filter-container">
                <NavLink exact to="/" className="filter-link">Today</NavLink>
                <NavLink to="/weekend" className="filter-link">Weekend</NavLink>
                <NavLink to="/next-weekend" className="filter-link">Next Weekend</NavLink>
                <NavLink to="/custom-dates-search" className="filter-link">Custom Dates</NavLink>
            </div>
        )
    }
}

export default QuickFilter;