import React, { Component } from 'react';
import Event from './Event';

class EventList extends Component {
    render() {
        return (
            <section className="content">
                    {this.props.events.map((event, index) => (
                        <Event key={index} item={event}></Event>
                    ))}
            </section>
        );
    }
}

export default EventList;