import React, { Component } from 'react';
import ReactGA from 'react-ga';

class Event extends Component {
    render() {
        return (
            <div className="event" id={this.props.item.id}>
                <ReactGA.OutboundLink
                    eventLabel={this.props.item.originUrl}
                    to={this.props.item.originUrl}
                    target="_blank"
                >
                    <img src={originImgOrDefault(this.props.item.logoUrl)} alt={shortName(this.props.item.name)}></img>
                    <h2>{shortName(this.props.item.name)}</h2>
                    <p className="eventDate">{formatDate(this.props.item.beginDate + "+02:00")}</p>
                    <p>{showAddress(this.props.item.address)}</p>
                </ReactGA.OutboundLink>
            </div>
        )
    }
};

export default Event;

function formatDate(value) {
    var date = new Date(value)

    return formatDateNumber(date.getDate()) +
        "." + formatDateNumber(date.getMonth() + 1) +
        "." + date.getFullYear() +
        " " + formatTime(date.getHours()) +
        ":" + formatTime(date.getMinutes());
};

function formatDateNumber(value) {
    if (value < 10) return "0" + value;
    return value;
};

function formatTime(time) {
    if (time > 0) return time;
    return "00";
}

function shortName(value) {
    return shortString(value, 60);
};

function shortString(value, max) {
    if (value !== null && value !== undefined) {
        if (value.length > max) {
            return value.substring(0, max) + "...";
        } else return value;
    }
    return "";
};

function originImgOrDefault(imgUrl) {
    if (typeof imgUrl !== 'undefined' && imgUrl !== null) {
        return imgUrl;
    }
    return "./munich_panorama.jpg";
};

function valueOr(value, defaultValue) {
    if (value === null || value === 'undefined') return defaultValue;
    return value;
};

function showAddress(address) {
    if (address === null || address === 'undefined') return "";
    return valueOr(address.street, "") + " " + valueOr(address.city, "");
};