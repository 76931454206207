import React, { Component } from 'react';

class ShowMoreBtn extends Component {

    onClick = () => {
        this.props.showMore();
    }

    render() {
        return (
            <div className="show-more-container">
                <button className="show-more-btn" onClick={this.onClick}>More Events</button>
            </div>
        )
    };
}

export default ShowMoreBtn;