import React, { Component } from 'react';
import { getTodayDate, getWeekendDates } from '../../functions/dates';
import { getEventsByDate } from '../../functions/search-events';
import { gaShowMoreBtnClick, gaPageView } from '../../functions/ga';
import EventList from '../EventList';
import ShowMoreBtn from '../ShowMoreBtn';

class Weekend extends Component {
    state = {
        events: [],
        offset: 0,
        hasMoreItems: false,
        total: 0
    };

    // searching events with the date range
    dateSearch = (offset) => {
        getEventsByDate(offset, weekendBegin, weekendEnd, this.props.city).then(res => {
            this.setState({
                events: offset === 0 ? [...res.events] : [...this.state.events, ...res.events],
                offset: res.nextOffset,
                hasMoreItems: res.hasMoreItems,
                total: res.total
            })
        })
    };

    //loading more event
    showMoreEvents = () => {
        gaShowMoreBtnClick(weekendBegin, weekendEnd);

        this.dateSearch(this.state.offset);
    }

    componentDidMount() {
        gaPageView();
        this.dateSearch(this.state.offset);
    };

    render() {
        return (
            <React.Fragment>
                <EventList events={this.state.events} />
                {this.state.hasMoreItems && <ShowMoreBtn showMore={this.showMoreEvents} />}
            </React.Fragment>
        )
    };
}

const weekendJson = JSON.parse(getWeekendDates(getTodayDate()))
const weekendBegin = weekendJson.begin
const weekendEnd = weekendJson.end

export default Weekend;