import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import QuickFilter from './components/QuickFilter';
import Today from './components/pages/Today';
import Weekend from './components/pages/Weekend';
import NextWeekend from './components/pages/NextWeekend';
import CustomDates from './components/pages/CustomDates';
import { initGA } from './functions/ga';
import CookieConsent from "react-cookie-consent";

import './App.css';

class App extends Component {

  componentDidMount() {
    initGA();
  };

  render() {
    return (
      <Router>
        <div className="App">
          <header>
            <h1 className="logo">München Events Lookup</h1>
            <QuickFilter />
          </header>
          <Route exact path="/" component={() => <Today city="Munich" />} />
          <Route path="/weekend" component={() => <Weekend city="Munich" />} />
          <Route path="/next-weekend" component={() => <NextWeekend city="Munich" />} />
          <Route path="/custom-dates-search" component={() => <CustomDates city="Munich" />} />
          <footer>
            <h3>München Events Lookup</h3>
          </footer>
          <CookieConsent>
            This website uses cookies to enhance the user experience.
        </CookieConsent>
        </div>
      </Router>
    );
  }
}

export default App;
