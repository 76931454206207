export const getTodayDate = () => Date.now();

export const dateStr = (d) => new Date(d).toISOString().slice(0, 10);

export const todayDateStr = () => dateStr(getTodayDate());

const isSun = (d) => { return new Date(d).getDay() === 0; }
const isMon = (d) => { return new Date(d).getDay() === 1; }
const isTue = (d) => { return new Date(d).getDay() === 2; }
const isWed = (d) => { return new Date(d).getDay() === 3; }
const isThur = (d) => { return new Date(d).getDay() === 4; }
const isFri = (d) => { return new Date(d).getDay() === 5; }
const isSat = (d) => { return new Date(d).getDay() === 6; }

const getSatDate = (d) => {
    let date = new Date(d);

    if (isSun(date)) date.setDate(date.getDate() + 6);
    if (isMon(date)) date.setDate(date.getDate() + 5);
    if (isTue(date)) date.setDate(date.getDate() + 4);
    if (isWed(date)) date.setDate(date.getDate() + 3);
    if (isThur(date)) date.setDate(date.getDate() + 2);
    if (isFri(date)) date.setDate(date.getDate() + 1);

    return date;
};

const getSunDate = (d) => {
    let date = new Date(getSatDate(d));
    date.setDate(date.getDate() + 1);

    return date;
};

const weekLaterFrom = (d) => {
    let date = new Date(d);
    date.setDate(date.getDate() + 7)

    return date;
};

export const getWeekendDates = (d) => {
    const weekend = {
        begin: getSatDate(d),
        end: getSunDate(d)
    };
    return JSON.stringify(weekend);
};

export const getNextWeekendDates = (d) => {
    const weekendJson = JSON.parse(getWeekendDates(d));
    const nextWeekend = {
        begin: weekLaterFrom(weekendJson.begin),
        end: weekLaterFrom(weekendJson.end)
    };

    return JSON.stringify(nextWeekend);
};